@import '../../../../../../themes/variables.module.scss';

.wrapper-content-pedido {
  padding: 20px;

  .buttons {
    .flex-row-reverse {
      display: flex;
    }
  }

  .content-pedido-materiais {
    .col-lg-4 {
      .lista-tipo-arte {
        cursor: pointer !important;
        border-radius: 22px;
        margin: auto;
        margin: 14px;
        border: 3px solid transparent;
        max-width: 255px;
        min-width: 240px;

        .header-card {
          background-color: $secondary;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
          min-height: 70px;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          text-transform: uppercase;
          color: $white;
          text-align: center;
          font-weight: 600;
          font-size: 16px;
          line-height: 21px;
          padding-right: 10px;
          padding-left: 10px;

          i {
            position: absolute;
            left: 24px;
            color: lightgreen;
          }
        }

        .header-card.selecionado {
          background-color: #c76937 !important;
        }

        .body-card {
          input[type='checkbox'] {
            display: none;
          }

          label {
            width: 100%;
            margin: 0;
            cursor: pointer;

            img {
              border: 1px transparent;
              border-bottom-left-radius: 20px;
              border-bottom-right-radius: 20px;
              width: 255px;
              height: 255px;
            }

            li {
              color: $gray;
              padding: 5px 0 5px 20px;
              text-align: left;
            }
          }
        }
      }
      .lista-tipo-arte:hover {
        // box-shadow: 0 0 0 0.2rem $primary;
        border: 3px solid $primary;
        border-radius: 1.4rem;
      }
      .lista-tipo-arte.selecionado {
        border: 3px solid #c76937;
        border-radius: 1.4rem;
      }
    }
  }
  .lista-materiais .body-card ul label li:hover {
    background-color: $primary;
    color: $white;
    border-radius: 15px;
  }
}

@media only screen and (max-width: 485px) {
  .wrapper-content-pedido {
    .content-pedido-materiais {
      display: flex;
      flex-direction: column;
      align-items: center;

      .material {
        display: flex;
        flex-direction: column;
        width: 100%;

        .col-lg-4 {
          flex: 100%;
          max-width: 100%;

          .lista-materiais {
            box-shadow: 0px 3px 6px $black2;
            border-radius: 51px;
            .header-card {
              border-top-left-radius: 50px;
              border-top-right-radius: 50px;
              font-size: 22px;
              font-weight: 600;
              height: 100px;
            }
          }
        }
      }
    }
  }
}
