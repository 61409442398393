// Fonts
$font-family: 'Stag';

// Colors
$primary: #003975;
$primary-hover: #0066d3;
$primary-selecionado: #004f71;
$primary-light: #7daee3;
$danger: #e4002b;
$sucess: #00b140;
$sucess-light: #7ccc6c;

$secondary: #0069b8;
$theme-color-primary: #008aff;
$theme-color-secondary: #18327f;
$turn-off-the-light: rgba(0, 0, 0, 0.5);
$turn-off-the-lighter: rgba(0, 0, 0, 0.1);

$white: #fff;
$black: #000;
$black2: #00000029;
$white-half-opacity: rgba(255, 255, 255, 0.8);
$gray: #63666a;
$gray-light: #d9d9d6;
$gray-lighter: #f3f3f3;

$brackground: #fafafa;

$amountOfLight: 10%;
$primary-dark: darken($primary, $amountOfLight);

:export {
  primary: $theme-color-primary;
  primaryLight: $primary-light;
  secondary: $theme-color-secondary;
}
